// 处理人员
<template>

    <div class='selectPerson'>
        <el-dialog :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close"
                   :modal-append-to-body='false'>
            <el-row :gutter="15">
                <el-col :span="12">
                    <div class="treeBox">
                        <el-tree :data="treeData"
                                 :props="defaultProps"
                                 :default-expand-all="false"
                                 accordion
                                 @node-click="handleNodeClick">
                        </el-tree>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="personList">
                        <el-radio-group v-model="operatorId">
                            <p v-for="(item,idx) in personList"
                               :key="idx">
                                <el-radio :label="item.code">{{item.label}}</el-radio>
                            </p>
                        </el-radio-group>
                    </div>
                </el-col>
            </el-row>
            <!-- <p>已选：万物理想/技术中台/黄杉</p> -->
            <p v-if="personName">已选：{{personName}}</p>
            <div class="footer">
                <el-button type="primary"
                           @click="close">取消</el-button>
                <el-button type="success"
                           @click="onSubmit">确认</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "selectPerson",

    props: {
        title: {
            type: String,
            default: "处理人员",
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            treeData: [
                {
                    label: "全部人员",
                },
            ],
            defaultProps: {
                children: "children",
                label: "label",
            },
            personList: [], // 人员列表
            personName: "", // 已选择人员-名字
            operatorId: "", // 已选人员id
            choosePerson: {}, // 已选人员完整信息
        };
    },

    created() {},

    mounted() {
        // 获取处理人员列表
        this.getPersonList();
    },

    methods: {
        // 获取处理人员列表
        getPersonList() {
            let loading = Loading.service({
                target: document.querySelector(".personList"),
            });
            commonAPI
                .getPersonList()
                .then((res) => {
                    this.personList = res.content;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 选择组织架构
        handleNodeClick(data) {
            console.log(data);
        },
        // 提交表单
        onSubmit() {
            this.$emit(
                "close",
                this.personName,
                this.operatorId,
                this.choosePerson
            );
        },
    },

    computed: {},

    watch: {
        // 绑定姓名
        operatorId() {
            let filterArr = this.personList.filter((item) => {
                return item.code === this.operatorId;
            });
            this.personName = filterArr[0].label;
            this.choosePerson = filterArr[0];
        },
    },
};
</script>

<style lang='scss' scoped>
.treeBox,
.personList {
    border: 1px solid #ddd;
    height: 300px;
    overflow-y: scroll;
}
.personList .el-radio-group {
    width: 100%;
}
.personList p {
    padding: 8px 5px;
}
.personList p:hover {
    background: #eee;
}
.footer {
    text-align: center;
}
</style>