// 新建维护
// popSafeguardPlan
<template>

    <div class="popSafeguardPlan">
        <el-dialog title="创建维护计划"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="1136px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     :inline="false"
                     label-position="top">
                <div class="popItemBox">
                    <div class="title">设备信息</div>
                    <div class="p20">
                        <el-row :gutter="60">
                            <el-col :span="8">
                                <el-form-item label="选择维护设备"
                                              prop="equipmentName">
                                    <el-tooltip class="item"
                                                effect="dark"
                                                :disabled="!form.equipmentName"
                                                :content="form.equipmentName">
                                        <el-input v-model="form.equipmentName"
                                                  @click.native="openpopChooseDeviceByCustomt"
                                                  placeholder="请输入选择维护设备"
                                                  suffix-icon="el-icon-arrow-down"></el-input>
                                    </el-tooltip>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="目标客户"
                                              prop="systemId">
                                    <customer-or-provider-select-tree ref="selectTree"
                                                                      type="CUSTOMER"
                                                                      :disabled="true"
                                                                      :checkedInfo="customerInfo"
                                                                      @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="处理人员"
                                              prop="operatorName">
                                    <el-input v-model="form.operatorName"
                                              placeholder="请选择执行人"
                                              readonly
                                              clearable
                                              @clear="cleanoperatorId"
                                              @focus="openselectPerson">
                                        <i slot="suffix"
                                           v-show="!form.operatorName"
                                           class="el-icon-arrow-down"></i>
                                    </el-input>
                                    <!-- 选择处理人员 -->
                                    <pop-select-members v-if="selectPersonVisible"
                                                        title="选择执行人"
                                                        @close="closeselectPerson"></pop-select-members>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </div>
                </div>

                <div class="popItemBox">
                    <div class="title">报修人信息</div>
                    <div class="p20">
                        <el-link type="primary"
                                 @click="openselectCustomPerson"
                                 style="line-height: 35px">引用客户信息</el-link>
                        <span class="choosePerson"
                              v-if="islinkPerson">{{form.linkName}} <i class="el-icon-circle-close"
                               @click="delLinkPerson"></i></span>
                        <el-row :gutter="60">
                            <el-col :span="8">
                                <el-form-item label="姓名"
                                              prop="linkName">
                                    <el-input v-model="form.linkName"
                                              :disabled="islinkPerson"
                                              placeholder="请输入姓名"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="联系方式"
                                              prop="linkPhone">
                                    <el-input v-model="form.linkPhone"
                                              :disabled="islinkPerson"
                                              placeholder="请输入联系方式"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="单位"
                                              prop="unit">
                                    <el-input v-model="form.unit"
                                              :disabled="islinkPerson"
                                              placeholder="请输入单位"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="部门"
                                              prop="dept">
                                    <el-input v-model="form.dept"
                                              :disabled="islinkPerson"
                                              placeholder="请输入部门"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="popItemBox">
                    <div class="title">问题描述</div>
                    <div class="p20">
                        <el-row :gutter="60">
                            <el-col :span="24">
                                <el-form-item label="故障类型"
                                              prop="problemType">
                                    <template v-if="errorItems && errorItems.length">
                                        <el-checkbox-group v-model="form.faultTypes">
                                            <el-checkbox-button v-for="item in errorItems"
                                                                :label="item"
                                                                :key="item">{{item}}</el-checkbox-button>
                                        </el-checkbox-group>
                                    </template>
                                    <p class="red"
                                       v-else><i class="el-icon-warning-outline"></i>&nbsp;&nbsp;该设备类型暂无可供选择的故障类型！</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="问题描述"
                                              prop="problemDescription">
                                    <el-input v-model="form.problemDescription"
                                              :rows="5"
                                              type="textarea"
                                              placeholder="请输入问题描述"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <!-- TODO 录音功能 -->
                                <div class="recordBox">
                                    <i class="el-icon-microphone"
                                       v-if="!isRecord"
                                       @click="translationStart"></i>
                                    <i class="el-icon-microphone recordIng"
                                       v-else
                                       @click="translationEnd"></i>
                                    <template v-if="recordFileId">
                                        <audio :src="`${PATH}/filem/view?id=${recordFileId}`"
                                               controls></audio>
                                        <el-popconfirm confirm-button-text="删除"
                                                       cancel-button-text="取消"
                                                       icon="el-icon-info"
                                                       icon-color="red"
                                                       @confirm="delRecordFile"
                                                       title="真的要删除吗？">
                                            <span class="delRecord el-icon-circle-close"
                                                  slot="reference">
                                            </span>
                                        </el-popconfirm>
                                    </template>
                                    <span v-else
                                          class="recordTip">{{isRecord ? "正在录音，再次点击左侧按钮可结束录音！" : "点击左侧按钮可进行录音！"}}</span>
                                </div>

                                <el-form-item label="图片/视频">
                                    <!-- 相册组件 -->
                                    <photo-album :photos="photos"
                                                 :videos="videos"
                                                 :needDel="true"
                                                 :isNeedEmptyBg="false">
                                        <div slot="upload"
                                             v-if="photos.length + videos.length < 9">
                                            <el-upload class="avatar-uploader"
                                                       :headers="configHeader"
                                                       :action="action"
                                                       :show-file-list="false"
                                                       :data="uploadData"
                                                       :on-success="uploadSuccess"
                                                       :on-error="uploadError"
                                                       :on-exceed="handleExceed"
                                                       :before-upload="beforeAvatarUpload"
                                                       :disabled="isUpdateing"
                                                       limit="6">
                                                <template v-if="isUpdateing">
                                                    <p v-loading="isUpdateing"
                                                       class="updateTip">正在上传
                                                        <br>请稍候！
                                                    </p>
                                                </template>
                                                <template v-else>
                                                    <img v-if="imageUrl"
                                                         :src="imageUrl"
                                                         class="avatar">
                                                    <i v-else
                                                       class="delFile el-icon-plus avatar-uploader-icon"></i>
                                                </template>
                                            </el-upload>
                                        </div>
                                    </photo-album>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

            <!-- 选择维护设备 -->
            <pop-choose-device-by-custom v-if="popChooseDeviceByCustomtVisible"
                                         @close="closepopChooseDeviceByCustomt"></pop-choose-device-by-custom>
            <!-- 选择客户 -->
            <pop-select-custom v-if="selectCustomPersonVisible"
                               title="添加客户"
                               :isNeedPositionName="true"
                               :showCheckbox="false"
                               :openTreeNode="openTreeNode"
                               @close="closeselectCustomPerson"></pop-select-custom>

        </el-dialog>

    </div>
</template>

<script>
import PhotoAlbum from "@/components/PhotoAlbumNew";
import PopSelectCustom from "../../../components/selectTree/popSelectCustom";
import PopSelectMembers from "../../../components/selectTree/popSelectMembers";
import CustomerOrProviderSelectTree from "../../../components/selectTree/customerOrProviderSelectTree";
import PopChooseDeviceByCustom from "@/modleComponents/Device/popChooseDeviceByCustom";
import { Loading } from "element-ui";
import API from "@/api/safeguardManage.js";
import commonAPI from "@/api/commonAPI.js";
// 引入录音
import Recorderx, { ENCODE_TYPE } from "recorderx";
export default {
    name: "popSafeguardPlan",

    props: [],

    components: {
        PhotoAlbum,
        PopSelectCustom,
        PopSelectMembers,
        CustomerOrProviderSelectTree,
        PopChooseDeviceByCustom,
    },

    data() {
        return {
            PATH: window.configPath.baseUrl,
            dialogVisible: true,

            photos: [], // 相册
            videos: [], // 相册视频
            imageUrl: "", // 上传图片

            form: {
                id: null,
                equipmentName: "", // 选择维护设备
                equipmentId: "", // 选择维护设备
                systemName: "", // 目标客户
                systemId: "", // 目标客户
                operatorId: "", // 处理人员
                operatorName: "", // 处理人员
                linkName: "", // 报修人
                linkPhone: "", // 联系方式
                problemDescription: "", // 问题描述
                faultTypes: [], // 故障类型
                from: "web", // 来源 后端区分是web还是h5报修
                unit: null,
                dept: null,
            },
            formRules: {
                equipmentName: [
                    {
                        required: true,
                        message: "选择维护设备",
                        trigger: "blur",
                    },
                ],
                linkName: [
                    {
                        required: true,
                        message: "请输入报修人或引用客户信息",
                        trigger: "blur",
                    },
                ],
                linkPhone: [
                    {
                        required: true,
                        validator: function (rule, value, callback) {
                            if (
                                /^1[0-9]{10}$/.test(
                                    value.replace(/\s+/g, "")
                                ) === false
                            ) {
                                callback(new Error("请输入正确的手机号"));
                            } else {
                                callback();
                            }
                        },
                        message: "未输入正确的手机号",
                        trigger: "blur",
                    },
                ],
                problemDescription: [
                    {
                        required: true,
                        message: "请输入问题描述",
                        trigger: "blur",
                    },
                ],
            },
            selectPersonVisible: false, // 选择处理人员弹窗显示
            selectCustomPersonVisible: false, // 选择引用客户弹窗显示
            popChooseDeviceByCustomtVisible: false, // 选择维护设备弹窗显示
            chooseDevice: null, // 选中的维护设备
            selectCustomPerson: false, // 选择客户弹窗显示
            jpgOrVideo: "jpg", // 是上传图片还是视频
            isUpdateing: false, // 正在上传
            errorItems: [], // 问题描述
            //上传数据
            uploadData: {
                resourceId: null,
                resourceType: "maintainProblemFile",
            },
            // 录音文件id
            recordFileId: null,
            isRecord: false, // 是否正在录音
            islinkPerson: false, // 是否是引用的客户
        };
    },

    created() {},

    mounted() {
        // 获取新增的Id
        this.getId();
    },

    methods: {
        // 选择引用客户信息
        openselectCustomPerson() {
            this.selectCustomPersonVisible = true;
        },
        // 关闭选择引用客户信息弹框
        closeselectCustomPerson(choosePerson) {
            if (choosePerson.length > 0) {
                this.islinkPerson = true;
                this.form.linkPhone = choosePerson[0].linkPhone;
                this.form.linkName = choosePerson[0].linkName;
                this.form.unit = choosePerson[0].companyName;
                this.form.dept = choosePerson[0].dept;
            }
            this.$refs.form.validateField("linkName");
            this.$refs.form.validateField("linkPhone");
            this.selectCustomPersonVisible = false;
        },
        // 取消引用
        delLinkPerson() {
            this.islinkPerson = false;
            this.form.linkPhone = "";
            this.form.linkName = "";
            this.form.unit = "";
            this.form.dept = "";
            this.$refs.form.validateField("linkName");
            this.$refs.form.validateField("linkPhone");
        },
        //目标客户的选择
        selectTreeCustClick(node) {
            this.form.systemId = node.id;
        },
        // 选择添加处理人员
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择添加处理人员弹框
        closeselectPerson(id, name) {
            if (id && name) {
                this.$set(this.form, "operatorName", name); // 处理人员id
                this.$set(this.form, "operatorId", id); // 处理人员名字
            }
            this.selectPersonVisible = false;
        },
        // 删除处理人员
        cleanoperatorId() {
            this.$set(this.form, "operatorName", ""); // 处理人员
            this.$set(this.form, "operatorId", ""); // 处理人员名字
        },
        // 选择维护设备 打开弹框
        openpopChooseDeviceByCustomt() {
            this.popChooseDeviceByCustomtVisible = true;
        },
        // 选择维护设备 关闭弹框
        closepopChooseDeviceByCustomt(row) {
            this.chooseDevice = row || null;
            this.popChooseDeviceByCustomtVisible = false;
        },
        // 获取列表
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".footer"),
                    });
                    API.saveSafeguard(this.form)
                        .then(() => {
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            loading.close();
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                } else {
                    this.$message({
                        message: "请检查必填信息是否填写完整！",
                        type: "error",
                    });
                    return false;
                }
            });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 获取新增项目的Id
        getId() {
            let loading = Loading.service({
                target: document.querySelector(".popSafeguardPlan"),
            });
            commonAPI
                .getId()
                .then((res) => {
                    loading.close();
                    this.form.id = res.content;
                    this.uploadData.resourceId = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取设备故障列表
        getErrorItems() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = {
                qrCode: this.form.equipmentId,
            };
            API.getErrorItems(data)
                .then((res) => {
                    this.errorItems = res.content || null;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 上传图片
        // 上传前检测
        beforeAvatarUpload(file) {
            const isJPG =
                file.type.indexOf("image") !== -1 ||
                file.type.indexOf("video") !== -1;
            if (!isJPG) {
                this.$message.error("该处只能上传图片和视频！");
            } else {
                this.isUpdateing = true;
                if (file.type.indexOf("image") !== -1) {
                    this.jpgOrVideo = "jpg";
                    this.uploadData.resourceType = "maintainProblemFile";
                } else {
                    this.jpgOrVideo = "video";
                    this.uploadData.resourceType = "maintainProblemVideoFile";
                }
            }
            return isJPG;
        },
        //上传成功
        uploadSuccess(data) {
            if (data.code === 200) {
                if (this.jpgOrVideo === "jpg") {
                    this.photos.push(data.content);
                } else {
                    this.videos.push(data.content);
                }
            } else {
                this.$message({
                    message: data.message || "上传失败",
                    type: "error",
                });
            }
            this.isUpdateing = false;
        },
        //上传失败
        uploadError() {
            this.isUpdateing = false;
        },
        // 限制上传个数
        handleExceed() {
            if (this.photos.length + this.videos.length > 6) {
                this.$message({
                    message: "最多只能上传9个图片和视频",
                    type: "error",
                });
            }
        },

        // 录音开始
        translationStart() {
            if (this.recordFileId) {
                this.$message({
                    message: "您已有一条录音了，若需要重新录入，请删除后重试！",
                    type: "error",
                });
                return;
            }
            this.isRecord = true;
            let that = this;
            that.rc = new Recorderx();
            that.$nextTick(() => {
                that.rc
                    .start()
                    .then(() => {
                        console.log("start recording");
                    })
                    .catch((error) => {
                        console.log("Recording failed.", error);
                    });
            });
        },

        // 录音结束
        translationEnd() {
            this.isRecord = false;
            this.rc.pause();
            var wav = this.rc.getRecord({
                encodeTo: ENCODE_TYPE.WAV,
            });
            this.uplode(wav);
        },

        // 生成随机数
        getMath() {
            return (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1);
        },

        // 上传音频
        uplode(wav) {
            let loading = Loading.service({
                target: document.querySelector(".recordBox"),
            });
            //上传音频
            var formData = new window.FormData();
            var name =
                this.getMath() + this.getMath() + this.getMath() + ".wav";
            formData.append("file", wav, name);
            formData.append("resourceId", this.form.id);
            formData.append("resourceType", "maintainProblemAudioFile");
            console.log(wav);
            console.log(formData);
            var options = {
                // 设置axios的参数
                url: "/filem/upload",
                data: formData,
                method: "post",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            this.$axios(options)
                .then((res) => {
                    this.$message({
                        message: "上传成功！",
                        type: "success",
                    });
                    this.recordFileId = res.data.content || null;
                    loading.close();
                })
                .catch(() => {
                    this.$message({
                        message: "上传失败，请稍后再试！",
                        type: "error",
                    });
                    loading.close();
                });
        },
        // 删除音频
        delRecordFile() {
            let loading = Loading.service({
                target: document.querySelector(".recordBox"),
            });
            commonAPI
                .deleteFileById({ id: this.recordFileId })
                .then(() => {
                    loading.close();
                    this.recordFileId = null;
                    this.$message({ message: "操作成功！", type: "success" });
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {
        customerInfo() {
            return { label: this.form.systemName, value: this.form.systemId };
        },
        openTreeNode() {
            return {
                id: this.form.systemId,
                name: this.form.systemName,
            };
        },
        // 上传文件所需
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },
        //上传地址
        action() {
            return `${window.configPath.baseUrl}/filem/upload`;
        },
    },

    watch: {
        chooseDevice() {
            if (this.chooseDevice) {
                this.form.systemId = ""; // 目标客户
                this.form.systemName = ""; // 目标客户
                this.form.equipmentName = `${this.chooseDevice.qrCode}（${this.chooseDevice.empType}/${this.chooseDevice.typeModel}）`; // 选择维护设备
                this.form.equipmentId = this.chooseDevice.qrCode; // 选择维护设备
                this.form.systemId = this.chooseDevice.systemId; // 目标客户
                this.form.systemName = this.chooseDevice.systemName; // 目标客户
                this.$refs.form.validateField("equipmentName");
                this.getErrorItems();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.updateTip {
    height: 100px;
    width: 100px;
    text-align: center;
    margin: 0;
    padding-top: 15px;
    ::v-deep .el-loading-mask {
        opacity: 0.5;
    }
}
.choosePerson {
    padding: 5px 5px 5px 15px;
    border: 1px solid rgb(201, 200, 200);
    border-radius: 5px;
    margin-left: 10px;
    i {
        margin-left: 5px;
        cursor: pointer;
    }
}
</style>