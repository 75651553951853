// 维护管理
<template>

    <div class='safeguardManage baseBg'>
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     style="width: 100%"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="处理人">
                    <el-input v-model="searchForm.operatorName"
                              placeholder="请输入处理人"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="目标客户">
                    <customer-or-provider-select-tree ref="selectTree"
                                                      type="CUSTOMER"
                                                      :shouldClean="true"
                                                      @handClear="handClear"
                                                      @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
                <el-button type="success"
                           class="flr"
                           size="default"
                           @click="openpopSafeguardPlan">新建维护计划</el-button>
                <el-button type="success"
                           class="flr mr15"
                           size="default"
                           @click="openpopHandPersonManage">处理人员管理</el-button>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          :height="tableHeight"
                          stripe>
                    <el-table-column type="index"
                                     width="50"
                                     label="序号">
                    </el-table-column>
                    <el-table-column prop="addr"
                                     label="报修地点"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="typeModel"
                                     label="设备型号"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="linkName"
                                     label="报修人"
                                     align="left"
                                     min-width="auto"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="linkPhone"
                                     label="联系方式"
                                     align="left"
                                     min-width="auto"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="createTime"
                                     label="报修时间"
                                     align="left"
                                     min-width="auto"
                                     width="185">
                    </el-table-column>
                    <el-table-column prop="belongDeptName"
                                     label="设备所属部门"
                                     align="left"
                                     min-width="auto"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="operatorName"
                                     label="处理人员"
                                     align="left"
                                     min-width="auto"
                                     width="auto">
                    </el-table-column>
                    <!-- <el-table-column prop="type"
                                        label="维护类型"
                                        align="left"
                                        min-width="auto"
                                        width="auto">
                    </el-table-column>
                    <el-table-column prop="customerName"
                                        label="目标客户"
                                        align="left"
                                        min-width="auto"
                                        width="auto">
                    </el-table-column>
                    <el-table-column prop="customerDefaultLinkName"
                                        label="负责人"
                                        align="left"
                                        min-width="auto"
                                        width="auto">
                    </el-table-column> -->
                    <el-table-column prop="id"
                                     label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看</el-link>
                            <el-link type="danger"
                                     @click="change(scope.row)">移交</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>

        <!-- 弹框 -->
        <selectPerson v-if="selectPersonVisible"
                      @close="closeselectPerson"></selectPerson>

        <!-- 新建维护 -->
        <popSafeguardPlan v-if="popSafeguardPlanVisible"
                          @update="getList"
                          @close="closepopSafeguardPlan"></popSafeguardPlan>

        <!-- 处理人员管理 -->
        <pop-hand-person-manage v-if="popHandPersonManageVisible"
                                @close="closepopHandPersonManage"></pop-hand-person-manage>

    </div>

</template>

<script>
import PopHandPersonManage from "./components/popHandPersonManage";
import CustomerOrProviderSelectTree from "../../components/selectTree/customerOrProviderSelectTree";
import PopSafeguardPlan from "./components/popSafeguardPlan";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import selectPerson from "./components/popSelectPerson";
import API from "@/api/safeguardManage.js";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "safeguardManage",

    props: [],

    components: {
        PopHandPersonManage,
        CustomerOrProviderSelectTree,
        PopSafeguardPlan,
        Pagination,
        selectPerson,
    },

    data() {
        return {
            selectPersonVisible: false, // 选择人员弹窗显示
            // 筛选表单
            searchForm: {
                operatorName: "", // 处理人员
                systemId: "", // 目标客户
                page: 1,
                size: 20,
                waitResolveFlag: true, // 是否查询维护管理列表，传true则查询维护管理列表，传false则查询维护记录列表
            },

            pageTotal: 0, // 分页总数
            typeOpts: [{ label: "全部", value: "" }],
            time: [],
            tableData: {
                data: [],
            },
            popSafeguardPlanVisible: false, // 新建维护弹窗显示
            popHandPersonManageVisible: false, // 处理人员管理弹窗显示
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 维护管理 列表
        this.getList();
    },

    mounted() {
        // 维护管理 列表
        this.getList();
    },

    methods: {
        // 处理人员管理 打开弹框
        openpopHandPersonManage() {
            this.popHandPersonManageVisible = true;
        },
        // 处理人员管理 关闭弹框
        closepopHandPersonManage() {
            this.popHandPersonManageVisible = false;
        },
        // 目标客户的选择
        selectTreeCustClick(node) {
            this.searchForm.systemId = node.id;
        },
        // 目标客户的清空
        handClear() {
            this.searchForm.systemId = "";
        },
        // 新建维护 打开弹框
        openpopSafeguardPlan() {
            this.popSafeguardPlanVisible = true;
        },
        // 新建维护 关闭弹框
        closepopSafeguardPlan() {
            this.popSafeguardPlanVisible = false;
        },
        // 移交任务
        change(row) {
            this.nowRow = row;
            this.selectPersonVisible = true;
        },
        // 关闭移交任务
        closeselectPerson(name, id) {
            if (id) {
                let loading = Loading.service({
                    target: document.querySelector(".tableBox"),
                });
                API.changeOperatorSafeguardManage({
                    operatorId: id,
                    maintainId: this.nowRow.id,
                })
                    .then(() => {
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                        this.nowRow = "";
                        this.getList();
                        loading.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
            this.selectPersonVisible = false;
        },
        // 维护管理 列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            commonAPI
                .listSafeguardManage(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 新建任务
        addNewTask() {
            this.addNewTaskVisible = true;
        },

        // 关闭新建任务
        closeaddNewTask() {
            this.addNewTaskVisible = false;
        },

        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },

        // 跳转任务详情
        details(row) {
            this.$router.push({
                path: "/deviceManage/safeguardDetails",
                query: { id: row.id },
            });
        },

        //清空筛选条件
        resetSearchForm() {
            this.$refs.selectTree.cleanSelect(); //清空供应商
            this.searchForm.operatorName = "";
            this.searchForm.systemId = "";
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
