// 选择维护设备
<template>

    <div class="popChooseDeviceByCustom">
        <el-dialog :close-on-click-modal="false"
                   title="选择维护设备"
                   :visible.sync="dialogVisible"
                   width="850px"
                   :modal-append-to-body='false'
                   :before-close="close">
            <div class="searchForm mb15">
                <el-form :model="searchForm"
                         ref="searchForm"
                         :inline="true">
                    <el-form-item label="目标客户"
                                  prop="systemId">
                        <customer-or-provider-select-tree ref="selectTree"
                                                          type="CUSTOMER"
                                                          :shouldClean="true"
                                                          @handClear="handClear"
                                                          @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                    </el-form-item>
                    <el-form-item label="归属部门"
                                  prop="belongDeptId">
                        <dept-select-tree ref="selectDepTree" placeholderStr="请选择部门"
                                          :needClean="needClean"
                                          :shouldClean="true"
                                          @handClear="clearDept"
                                          @treeSelectNodeClick="departmentClick"></dept-select-tree>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                                   @click="getList">搜索</el-button>
                        <el-button type=""
                                   @click="resetSearchForm">清除</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="miniTable mb30">
                <el-table ref="multipleTable"
                          :data="tableData"
                          tooltip-effect="dark"
                          height="300px"
                          style="width: 100%">
                    <el-table-column label="选择"
                                     align="center"
                                     min-width="65">
                        <template slot-scope="scope">
                            <el-radio :label="scope.$index"
                                      v-model="radio"
                                      @change.native="getCurrentRow(scope.row)"></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="qrCode"
                                     label="设备编号"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="empType"
                                     label="设备类型"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="provider"
                                     label="供应商"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="typeModel"
                                     label="规格型号"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="systemName"
                                     label="所在地点"
                                     align="center"
                                     min-width="120"></el-table-column>
                    <el-table-column prop="belongDeptName"
                                     label="归属部门"
                                     align="center"
                                     min-width="100"></el-table-column>
                </el-table>
            </div>

            <div class="footerControl">
                <el-button type="default"
                           size="medium"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           size="medium"
                           @click="save">保存</el-button>

            </div>

        </el-dialog>

    </div>
</template>

<script>
import DeptSelectTree from "../../components/selectTree/deptSelectTree";
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popChooseDeviceByCustom",

    props: [],

    components: {
        DeptSelectTree,
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
    },

    data() {
        return {
            dialogVisible: true,
            searchForm: {
                systemId: "", // 目标客户
                maintainFlag: true,
                belongDeptId: "",
            },
            radio: "",
            tableData: [],
            templateSelection: [],
        };
    },

    created() {},

    mounted() {
        // 获取储备项目列表
        this.getList();
    },

    methods: {
        // 目标客户的选择
        selectTreeCustClick(node) {
            this.searchForm.systemId = node.id;
        },

        // 目标客户的清空
        handClear() {
            this.searchForm.systemId = "";
        },

        //部门的选择
        departmentClick(node) {
            this.searchForm.belongDeptId = node.id;
        },
        //部门清空
        clearDept() {
            this.searchForm.belongDeptId = "";
        },

        //获取选中数据
        getCurrentRow() {
            this.templateSelection = this.tableData[this.radio];
        },
        // 保存
        save() {
            if (this.radio === "") {
                this.$message({ message: "你还没有选择数据！", type: "error" });
                return;
            }
            this.getCurrentRow();
            this.$emit("close", this.templateSelection);
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 获取设备列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".miniTable"),
            });
            let data = Object.assign({}, this.searchForm, {
                menuId: "1332134314420809730",
            });
            commonAPI
                .getDeviceManageList(data)
                .then((res) => {
                    loading.close();
                    this.tableData = res.content.records;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 清空表单
        resetSearchForm() {
            this.handClear();
            this.clearDept();
            this.$refs.selectTree.$refs.selectTree.cleanSelect();
            this.$refs.selectDepTree.$refs.selectTree.cleanSelect();
            // 查询列表
            this.getList();
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.miniTable ::v-deep .el-radio__label {
    display: none;
}
.footerControl {
    text-align: right;
}
.footerControl .el-button {
    margin: 0 15px;
}
</style>