// 根据公司部门选择人员
<template>

    <div class="selectPersonInDept">
        <el-select :placeholder="placeholder || '请选择处理人员'"
                   :value="value"
                   @input="change($event)"
                   :filterable="true"
                   clearable>
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
        </el-select>
    </div>

</template>

<script>
import { Loading } from "element-ui";
import selectOptsAPI from "@/api/selectOpts.js";
export default {
    name: "selectPersonInDept",

    props: {
        value: String,
        // 部门Id
        deptId: {
            type: String,
        },
        // 提示语
        placeholder: {
            type: String,
        }
    },

    components: {},

    data() {
        return {
            options: [],
        };
    },

    created() {},

    mounted() {
        // 公司下的联系人列表
        this.getPersonList();
    },

    methods: {
        change: function (val) {
            this.$emit("input", val);
        },
        // 公司下的联系人列表
        getPersonList() {
            let loading = Loading.service({
                target: document.querySelector(".selectPersonInDept"),
            });
            return selectOptsAPI
                .getUserList({
                    deptId: this.deptId,
                    size: 1000,
                })
                .then((res) => {
                    let opt = res.content.records.map((val) => {
                        return {
                            label: val["userName"],
                            value: val["id"],
                        };
                    });
                    this.options = this.options.concat(opt);
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .selectPersonInDept{

// }
</style>
