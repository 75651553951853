// 处理人员管理
// popHandPersonManage
<template>

    <div class="popHandPersonManage">
        <el-dialog title="处理人员管理"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="500px"
                   :before-close="close">
            <el-form :model="searchForm"
                     ref="searchForm"
                     :rules="searchFormRules"
                     label-width="40px"
                     :inline="false">
                <el-form-item label="部门"
                              prop="deptId">
                    <dept-select-tree ref="selectDepTree"
                                      placeholderStr="请选择部门"
                                      :needClean="needClean"
                                      :shouldClean="true"
                                      @handClear="clearDept"
                                      @treeSelectNodeClick="departmentClick"></dept-select-tree>
                </el-form-item>
            </el-form>
            <div class="deptList">
                <el-row :gutter="5"
                        class="deptItem"
                        v-for="(item,idx) in handPersonList"
                        :key="idx">
                    <el-col :span="10">{{item.deptName}}</el-col>
                    <el-col :span="14">
                        <select-person-in-dept v-model="item.userId"
                                               :deptId="item.deptId"></select-person-in-dept>
                    </el-col>
                </el-row>
            </div>
            <div class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="save">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import SelectPersonInDept from "../../../modleComponents/person/selectPersonInDept";
import API from "@/api/safeguardManage.js";
import { Loading } from "element-ui";
import DeptSelectTree from "../../../components/selectTree/deptSelectTree";
export default {
    name: "popHandPersonManage",

    props: [],

    components: {
        SelectPersonInDept,
        DeptSelectTree,
    },

    data() {
        return {
            dialogVisible: true,

            searchForm: {
                deptId: "", // 项目名称
            },
            searchFormRules: {},

            handPersonList: [], // 处理人员列表
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        //部门的选择
        departmentClick(node) {
            this.searchForm.deptId = node.id;
            // 获取列表
            this.getList();
        },
        //部门清空
        clearDept() {
            this.searchForm.deptId = "";
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector("popHandPersonManage"),
            });
            API.getHandPersonList({
                deptId: this.searchForm.deptId,
            })
                .then((res) => {
                    loading.close();
                    this.handPersonList = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 清空表单
        resetSearchForm() {
            this.clearDept();
            this.$refs.selectDepTree.$refs.selectTree.cleanSelect();
            // 查询列表
            this.getList();
        },
        // 保存处理人员
        save() {
            let loading = Loading.service({
                target: document.querySelector("popHandPersonManage"),
            });
            let personList = this.handPersonList.map((val) => {
                return {
                    userId: val["userId"],
                    deptId: val["deptId"],
                };
            });
            API.saveHandPerson(personList)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.el-form {
    padding: 0 15px;
    border-bottom: 1px solid #ddd;
}
.deptList {
    padding: 15px;
    height: 300px;
    overflow-y: scroll;

    .deptItem {
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px;
        padding-bottom: 5px;

        .el-select {
            width: 100%;
        }
    }
}
</style>